let BASE_URL;
//打包环境判断
if (process.env.NODE_ENV == "production") {
  // console.log('正式环境')
  // BASE_URL = "https://sxwl.worldmaipu.com/";
  // BASE_URL = "http://192.168.1.234:6780/";
  BASE_URL = "https://dydl.zysxh5.cn/sxly-api/";
  
} else {
  // console.log('测试环境')
  //不用跨域
  // Vue.prototype.GLOBAL.BASE_URL = "http://192.168.1.8:8081"
  //跨域，proxyTable代理，自定义如/api
  BASE_URL = "/sxly-api";
}
// const ImgUrl = "http://192.168.1.234:6780/" // 配置全局静态资源路径
// const ImgUrl = "https://sxwl.worldmaipu.com/" // 配置全局静态资源路径
const ImgUrl = "https://dydl.zysxh5.cn/" // 配置全局静态资源路径
let u = navigator.userAgent;
let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
let isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
let isWx = u.toLowerCase().match(/MicroMessenger/i) == "micromessenger";
let isPc = !/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent);

export default {
  BASE_URL, //接口地址
  ImgUrl, //静态文件域名
  isAndroid, //是否在安卓
  isIos, //是否在ios
  isWx, //是否在微信
  isPc //是否在pc
};
