import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css'
import global from "./assets/js/global"
import commonFun from "./assets/js/commonFun"
import { Swipe, SwipeItem } from 'mint-ui';
import wx from "./assets/js/wx";
// import Vconsole from 'vconsole'
//  const vConsole = new Vconsole() 
Vue.component(Swipe.name, Swipe);
Vue.component(SwipeItem.name, SwipeItem);
Vue.config.productionTip = false
Vue.prototype.$global= global;
Vue.prototype.wechat = wx;
Vue.prototype.commonFun = commonFun;
Vue.use(MintUI);
wx.wxConfig(
  false, {
    title: "七彩长虹", // 分享标题
    link: window.location.href, // 分享链接
    desc: "", // 分享描述
    imgUrl: "", // 分享图标
  },
  function () {
    //分享成功后的回调函数
    console.log("分享成功");
  }
);
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
