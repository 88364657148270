const routes = [
    {
        path: '/',
        component: () => import('../views/start/start')
    },
    {
        path: '/index',
        component: () => import('../views/index/index'),
        // meta: {
        // 	keepAlive: true  // 需要缓存
      	//   }
    },   
     {
        path: '/scenic',
        component: () => import('../views/scenic/scenic')
    },
    {
        path: '/special/:index/:languageType',
        component: () => import('../views/special/special')
    },
    // {
    //     path: '/introduce',
    //     component: () => import('../views/detail/introduce')
    // },
    {
        path: '/scenicDetail/:pid/:languageType',
        component: () => import('../views/detail/scenicDetail')
    },
    // {
    //     path: '/visitorsDetail',
    //     component: () => import('../views/detail/visitorsDetail'),
    // },
    // {
    //     path: '/search',
    //     component: () => import('../views/search/search')
    // },
    {
        path: '/searchPoi/:id',
        component: () => import('../views/search/searchPoi')
    },
    // {
    //     path: '/audio',
    //     component: () => import('../components/audio/audio')
    // },
    {
        path: '/visitors/:id',
        component: () => import('../views/visitors/visitors')
    },
    {
        path: '/infoWindow',
        component: () => import('../components/infoWindow/infoWindow')
    },
    {
        path: '/round',
        component: () => import('../views/round/round')
    },
    {
        path: '/bank',
        component: () => import('../views/detail/bank'),
    },  
    {
        path: '/message',
        component: () => import('../views/message/message'),
    },  
    {
        path: '/messDe/:id',
        component: () => import('../views/message/messDe'),
    }, 
    {
        path: '/nav',
        component: () => import('../views/nav/nav'),
    },   
]
export default routes